import "./Slider.css";
import Slider from "react-slick";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
// import Button from "react-bootstrap/Button";
import { Link as LinkS } from "react-scroll";

const MainSlider = () => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <>
      <Container fluid id="slider" className="p-0 m-0">
        <Slider {...settings} className=" p-0 mb-0">
          <div className="bg-slider">
            <Container>
              <div
                className="row align-items-center "
                style={
                  {
                    // paddingTop: "50px",
                    // paddingBottom: "50px",
                  }
                }
              >
                <div className="col-lg-7 col-md-12">
                  <div className="d-block">
                    {/* <Image
                    className="w-100"
                    src={
                      process.env.PUBLIC_URL +
                      "/images/undraw_gaming_re_cma2.svg"
                    }
                    alt="slider image"
                  /> */}

                    <Image
                      className="w-100"
                      src={process.env.PUBLIC_URL + "/images/Saly-19-cut.png"}
                      alt="slider image"
                    />
                  </div>
                </div>
                <div className="col-lg-5 col-md-12 p-5">
                  <div className="caption mb-md-0 mb-5 text-white">
                    <h1 className="mb-4">
                      <span className="title">Welcome To</span>

                      <div>Studio AMK</div>
                    </h1>
                    <p className="amk-p">
                      We are the No.(1) digital contents distribution and
                      digital goods payment platform in Myanmar. We are working
                      with local/global game developers, game publishers &
                      payment providers to provide convenience ways to
                      distribute & accept payments for all parties. We are the
                      developers of Shan Koe Mee (the first multiplayer online
                      game in Myanmar).
                    </p>
                    <LinkS to="contact">
                      {/* <Button className="game-button">Contact</Button> */}
                      <button className="button-24">Contact</button>
                    </LinkS>
                  </div>
                </div>
              </div>
            </Container>
          </div>
        </Slider>
      </Container>
    </>
  );
};

export default MainSlider;

import styled from "styled-components";
import { FaTimes } from "react-icons/fa";
import { Link as LinkS } from "react-scroll";

export const SidebarContainer = styled.aside`
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 36%;
  background: #fff;
  display: grid;
  align-items: center;
  /* top: 0; */
  left: 0;
  transition: 0.3s ease-in-out;
  opacity: ${({ isOpen }) => (isOpen ? "100%" : "0")};
  top: ${({ isOpen }) => (isOpen ? "0" : "-100%")};

  @media screen and (min-width: 768px) {
    display: none;
  }
`;
export const Icon = styled.div`
  position: absolute;
  font-size: 1.2rem;
  top: 1.2rem;
  right: 1.5rem;
  background: #fff;
  /* transform: translate(-100%, 80px); */

  // @media screen and (max-width: 768px) {
  //   right: 1.9rem;
  // }
`;
export const CloseIcon = styled(FaTimes)`
  color: #fff;
  cursor: pointer;
`;

export const SidebarMenu = styled.div`
  //   display: grid;
  //   grid-template-rows: 400px;
  //   grid-template-columns: 1fr;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
`;

export const SidebarItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  padding-top: 50px;
`;
export const SidebarLink = styled(LinkS)`
  text-decoration: none;
  color: #555;
  font-size: 0.8rem;
  transition: 0.2s ease-in-out;
  font-weight: 600;
  border-radius: 10px;
  padding: 5px 10px;
  text-decoration: none;
  cursor: pointer;
  text-transform: uppercase;
  margin-top: 7px;

  &:hover {
    background: #00000036 !important;
    color: #f90000 !important;
  }
  &:active {
    // background:({ active }) => active ? #e6007e :#000;
    background: #00000036 !important;
    color: #f90000 !important;
  }
`;

import "./Service.css";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GiGamepadCross } from "react-icons/gi";
import { BsFillFileEarmarkCodeFill } from "react-icons/bs";
import { MdPayment } from "react-icons/md";

const Service = () => {
  return (
    <>
      <Container
        fluid
        id="service"
        className="service-bg-color align-items-center"
        style={{ paddingTop: "50px", paddingBottom: "50px" }}
      >
        <Container>
          <div className="row">
            <div className="col-md-6">
              <div className="row mb-3">
                <div className="our-product-title">
                  <h1 className="h1 mb-0">
                    Our <span className="header-style">Services</span>
                    <br />
                  </h1>
                </div>
              </div>
              <div className="row ">
                <div className="col">
                  <ul className="nav d-block m-auto">
                    <li>
                      <div
                        style={{
                          backgroundColor: "#fff",
                          padding: "20px",
                          marginBottom: "10px",
                          borderRadius: "10px",
                        }}
                      >
                        <Card
                          style={{
                            color: "#3B3B36",
                          }}
                        >
                          <Row className="align-items-center">
                            <Col>
                              <GiGamepadCross
                                size={50}
                                color="#c9c9c9"
                                className="icon"
                              />
                            </Col>
                            <Col md={10} style={{ textAlign: "left" }}>
                              <h2
                                style={{
                                  fontSize: "20px",
                                  fontWeight: "bold",
                                }}
                              >
                                Game Development
                              </h2>
                              We are the developer of No.1 Myanmar game portal,
                              MyanmarGame.com. We also provide gCoin payment
                              services which is a virtual currency for online
                              entertainment.
                            </Col>
                          </Row>
                        </Card>
                      </div>
                    </li>
                    <li>
                      <div
                        style={{
                          backgroundColor: "#fff",
                          padding: "20px",
                          marginBottom: "10px",
                          borderRadius: "10px",
                        }}
                      >
                        <Card
                          style={{
                            color: "#3B3B36",
                          }}
                        >
                          <Row className="align-items-center">
                            <Col>
                              <BsFillFileEarmarkCodeFill
                                size={50}
                                color="#c9c9c9"
                                className="icon"
                              />
                            </Col>
                            <Col md={10} style={{ textAlign: "left" }}>
                              <h2
                                style={{
                                  fontSize: "20px",
                                  fontWeight: "bold",
                                }}
                              >
                                Web Development
                              </h2>
                              We specialize in helping business run more
                              efficiently by developing web applications that
                              bring the different technology components of a
                              business together. Whether you need simple tools
                              that collect and display data, or an advanced
                              system with complex business logic, We look
                              forward to adding your web application development
                              project to the list.
                            </Col>
                          </Row>
                        </Card>
                      </div>
                    </li>
                    <li>
                      <div
                        style={{
                          backgroundColor: "#fff",
                          padding: "20px",
                          marginBottom: "10px",
                          borderRadius: "10px",
                        }}
                      >
                        <Card
                          style={{
                            color: "#3B3B36",
                          }}
                        >
                          <Row className="align-items-center">
                            <Col>
                              <MdPayment
                                size={50}
                                color="#c9c9c9"
                                className="icon"
                              />
                            </Col>
                            <Col md={10} style={{ textAlign: "left" }}>
                              <h2
                                style={{
                                  fontSize: "20px",
                                  fontWeight: "bold",
                                }}
                              >
                                Payment Platform
                              </h2>
                              'YWAR Platform' is just a Digital Content Platform
                              that can solve Payment problems for your adult
                              app/Game. Using many Payments, such as Mobile
                              Operators, Electronic Payments, Prepaid Cards,
                              Resellers and Online Payments, our main
                              intermediary, gCoin can be purchased at the 'YWAR
                              Platform'
                            </Col>
                          </Row>
                        </Card>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              {/* <div className="row">
                <div className="col">
                  <ul className="nav m-auto mt-5">
                    <li className="m-1">
                      <Button variant="secondary">
                        <ImChrome /> Web
                      </Button>
                    </li>
                    <li className="m-1">
                      <Button variant="secondary">
                        <ImAndroid /> Android
                      </Button>
                    </li>
                    <li className="m-1">
                      <Button variant="secondary">
                        <ImAppleinc /> ios
                      </Button>
                    </li>
                  </ul>
                </div>
              </div> */}
            </div>
            <div className="col-md-6 service-image-cover p-0 m-0">
              <Image
                src={process.env.PUBLIC_URL + "/images/Saly-10.png"}
                alt="Services"
              />
            </div>
          </div>
        </Container>
      </Container>
    </>
  );
};

export default Service;

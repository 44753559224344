import "./Navigation.css";
import { Link } from "react-scroll";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
// import Offcanvas from "react-bootstrap/Offcanvas";
import Image from "react-bootstrap/Image";
import { FaBars } from "react-icons/fa";

const Navigation = ({ toggle }) => {
  return (
    <>
      <Navbar
        key="md"
        bg="light"
        expand="md"
        className="m-0 sticky-top p-0 bg-white"
        style={{ zIndex: "999" }}
      >
        {/* <Container>
          <Navbar.Brand>
            <Image
              src={process.env.PUBLIC_URL + "/images/logo.png"}
              alt="logo"
              className="main-logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-md`} />
          <Navbar.Collapse
            id={`offcanvasNavbar-expand-md`}
            aria-labelledby={`offcanvasNavbarLabel-expand-md`}
            placement="end"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-md`}>
                <Navbar.Brand>
                  <Image
                    src={process.env.PUBLIC_URL + "/images/logo.png"}
                    alt="logo"
                    className="main-logo"
                  />
                </Navbar.Brand>
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="amk-nav justify-content-end flex-grow-1 pe-3">
                <Link
                  to="product"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}
                >
                  Products
                </Link>
                <Link
                  to="service"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}
                >
                  Services
                </Link>
                <Link
                  to="activity"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}
                >
                  Activities
                </Link>
                <Link
                  to="contact"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}
                >
                  Contact Us
                </Link>
              </Nav>
            </Offcanvas.Body>
          </Navbar.Collapse>
        </Container> */}
        <Container>
          <Navbar.Brand>
            <Image
              src={process.env.PUBLIC_URL + "/images/logo.png"}
              alt="logo"
              className="main-logo p-2"
            />
          </Navbar.Brand>
          <div className="mobileIcon" onClick={toggle}>
            <FaBars />
          </div>
          <Nav className="amk-nav justify-content-end flex-grow-1 pe-3">
            <Link
              to="product"
              smooth={true}
              duration={500}
              spy={true}
              exact="true"
              offset={-80}
            >
              Products
            </Link>

            <Link
              to="service"
              smooth={true}
              duration={500}
              spy={true}
              exact="true"
              offset={-80}
            >
              Services
            </Link>
            <Link
              to="activity"
              smooth={true}
              duration={500}
              spy={true}
              exact="true"
              offset={-80}
            >
              Activities
            </Link>
            <Link
              to="contact"
              smooth={true}
              duration={500}
              spy={true}
              exact="true"
              offset={-80}
            >
              Contact Us
            </Link>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
};

export default Navigation;

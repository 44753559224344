import "./App.css";
import Navigation from "./components/Navigation/Navigation";
import Product from "./components/Product/Product";
import MainSlider from "./components/Slider/Slider";
import Service from "./components/Service/Service";
import Partner from "./components/Partner/Partner";
import Contact from "./components/Contact/Contact";
import Footer from "./components/Footer/Footer";
import Activity from "./components/Activity/Activity";
import { useState } from "react";
import Sidebar from "./components/Navigation/Sidebar";

function App() {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div className="App">
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navigation toggle={toggle} />
      <MainSlider />
      <Partner />
      <Product />
      <Service />
      <Activity />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;

import "./Partner.css";
import Slider from "react-slick";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Card from "react-bootstrap/Card";

const Partner = () => {
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 2500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          speed: 2000,
          autoplaySpeed: 2000,
          infinite: true,
        },
      },
    ],
  };

  return (
    <div>
      <Container
        id="partner"
        className="bg-partner"
        style={{ paddingTop: "50px", paddingBottom: "50px" }}
      >
        <div className="row mb-3">
          <div className="our-product-title">
            <h1 className="h1 m-0">
              Our <span className="header-style">Partners</span>
              <br />
              {/* <div className="amk-title">
                <mark>
                  Studio <span style={{ color: "red" }}>AMK</span>
                </mark>{" "}
              </div> */}
            </h1>
          </div>
        </div>
        <div className="row text-center">
          <div className="col-md-8 m-auto">
            <div className="row">
              <Slider
                {...settings}
                direction="horizontal"
                style={{ overflow: "hidden" }}
              >
                <Card className="col">
                  <Card.Body>
                    <Image
                      src={process.env.PUBLIC_URL + "/images/razer.png"}
                      alt="logo"
                      style={{ width: "100%", height: "auto" }}
                      className="m-auto w-80 logo mb-2"
                    />
                    {/* <Card.Title className="text-dark">Razer</Card.Title> */}
                  </Card.Body>
                </Card>
                <Card className="col">
                  <Card.Body className="d-block">
                    <Image
                      src={process.env.PUBLIC_URL + "/images/MLBB.png"}
                      alt="logo"
                      style={{ width: "100%", height: "auto" }}
                      className="m-auto w-80 logo mb-2"
                    />
                    {/* <Card.Title className="text-dark">
                      Mobile Lengeds
                    </Card.Title> */}
                  </Card.Body>
                </Card>
                <Card className="col">
                  <Card.Body className="d-block">
                    <Image
                      src={process.env.PUBLIC_URL + "/images/steam.png"}
                      alt="logo"
                      style={{ width: "100%", height: "auto" }}
                      className="m-auto w-80 logo mb-2"
                    />
                    {/* <Card.Title className="text-dark">Steam</Card.Title> */}
                  </Card.Body>
                </Card>
              </Slider>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Partner;

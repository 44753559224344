import "./Activity.css";
import Container from "react-bootstrap/Container";

const Activity = () => {
  return (
    <>
      <Container
        fluid
        id="activity"
        className="product-bg-color align-items-center"
        style={{ paddingTop: "50px", paddingBottom: "50px" }}
      >
        <Container>
          <div className="row mb-3">
            <div className="our-product-title">
              <h1 className="h1 mb-4">
                Our <span className="header-style">Activities</span>
                <br />
              </h1>
            </div>
          </div>
          <div className="container-flex">
            <div className="column1">
              <div className="card">
                <div className="imgWrapper">
                  <img
                    src={process.env.PUBLIC_URL + "/images/a1.jpg"}
                    alt="activity"
                    className="image"
                  />
                </div>
                <div className="title act-title">Studio AMK</div>
                <div className="subTitle">
                  Studio AMK Family Staff Party Trip (Ngapali Beach)
                </div>
              </div>
            </div>
            <div className="column1">
              <div className="card">
                <div className="imgWrapper">
                  <img
                    src={process.env.PUBLIC_URL + "/images/a2.jpg"}
                    alt="activity"
                    className="image"
                  />
                </div>
                <div className="title act-title">Studio AMK</div>
                <div className="subTitle">
                  Studio AMK Internship Program (MTU-Third Batch)
                </div>
              </div>
            </div>
          </div>
          <div className="container-flex">
            <div className="column1">
              <div className="card">
                <div className="imgWrapper">
                  <img
                    src={process.env.PUBLIC_URL + "/images/a5.jpg"}
                    alt="activity"
                    className="image"
                  />
                </div>
                <div className="title act-title">Studio AMK</div>
                <div className="subTitle">
                  18th Yoma Yangon International Marathon (2020)
                </div>
              </div>
            </div>
            <div className="column1">
              <div className="card">
                <div className="imgWrapper">
                  <img
                    src={process.env.PUBLIC_URL + "/images/a3.jpg"}
                    alt="activity"
                    className="image"
                  />
                </div>
                <div className="title act-title">Studio AMK</div>
                <div className="subTitle">
                  Studio AMK Family Staff Party Trip (Vietnam)
                </div>
              </div>
            </div>
          </div>

          {/* <div className="row">
            <div className="col-md-6 col-sm-12">
              <Card style={{ textAlign: "center" }}>
                <Card.Body>
                  <Image
                    src={process.env.PUBLIC_URL + "/images/a1.jpg"}
                    alt="activity"
                    className="m-auto w-100 mb-2"
                    id="image"
                  />
                  <Card.Title className="text-start">Studio AMK</Card.Title>
                  <Card.Subtitle className="mb-2 text-start">
                    Studio AMK Family Staff Party Trip (Ngapali Beach)
                  </Card.Subtitle>
                </Card.Body>
              </Card>
            </div>
            <div className="col-md-6 col-sm-12">
              <Card>
                <Card.Body>
                  <Image
                    src={process.env.PUBLIC_URL + "/images/a2.jpg"}
                    alt="activity"
                    className="m-auto w-100 mb-2"
                    id="image"
                  />
                  <Card.Title className="text-start">Studio AMK</Card.Title>
                  <Card.Subtitle className="mb-2 text-start">
                    Studio AMK Internship Program (Mandalay Technological
                    University- Third Batch)
                  </Card.Subtitle>
                </Card.Body>
              </Card>
            </div>
            <div className="col-md-6 col-sm-12">
              <Card>
                <Card.Body>
                  <Image
                    src={process.env.PUBLIC_URL + "/images/a5.jpg"}
                    alt="activity"
                    className="m-auto w-100 mb-2"
                    id="image"
                  />
                  <Card.Title className="text-start">Studio AMK</Card.Title>
                  <Card.Subtitle className="mb-2 text-start">
                    18th Yoma Yangon International Marathon (2020)
                  </Card.Subtitle>
                </Card.Body>
              </Card>
            </div>
            <div className="col-md-6 col-sm-12">
              <Card>
                <Card.Body>
                  <Image
                    src={process.env.PUBLIC_URL + "/images/a3.jpg"}
                    alt="activity"
                    className="m-auto w-100 mb-2"
                    id="image"
                  />
                  <Card.Title className="text-start">Studio AMK</Card.Title>
                  <Card.Subtitle className="mb-2 text-start">
                    Studio AMK Family Staff Party Trip (Vietnam)
                  </Card.Subtitle>
                </Card.Body>
              </Card>
            </div>
          </div> */}
        </Container>
      </Container>
    </>
  );
};

export default Activity;

import "./Contact.css";
import Container from "react-bootstrap/Container";
import {
  ImPhone,
  ImEnvelop,
  ImMap,
  ImFacebook,
  // ImInstagram,
  // ImYoutube,
  // ImTwitter,
} from "react-icons/im";
import { SiGoogleplay } from "react-icons/si";
import { FaAppStoreIos } from "react-icons/fa";

const Contact = () => {
  return (
    <>
      <Container
        fluid
        id="contact"
        className="bg-white"
        style={{ paddingTop: "60px", paddingBottom: "60px" }}
      >
        <Container>
          <div className="row mb-3">
            <div className="our-product-title text-dark">
              <h1 className="h1 mb-3">
                <span className="header-style">Contact</span> Us
                <br />
              </h1>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-md-6 col-sm-12">
              <map>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3819.8480253287394!2d96.12426431481745!3d16.78423498844268!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30c1eb0dde8a7b33%3A0xb7d134b250b2006b!2sStudio%20AMK%20Co.%2C%20Ltd.!5e0!3m2!1smy!2smm!4v1659588512891!5m2!1smy!2smm"
                  width="100%"
                  height="400"
                  style={{ border: "0" }}
                  loading="lazy"
                  title="map"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </map>
            </div>
            <div className="col-md-6 col-sm-12">
              <ul className="contact-info d-block nav text-dark align-items-center">
                <li className="text-start">
                  <ImPhone /> <a href="tel:09777046931">+95 9777046931</a>
                </li>
                <li className="text-start">
                  <ImEnvelop />{" "}
                  <a href="mailto:info@studioamk.com">info@studioamk.com</a>
                </li>
                <li className="text-start">
                  <ImMap /> MM - No.43/44 (6A), 6th floor, MMM Condo, Strand
                  Road, Ahlone Tsp, Yangon
                </li>

                <li className="text-start">
                  <ImMap /> SG - North Bridge Road, #08-26A, Peninsula Plaza,
                  Singapore 179098
                </li>
              </ul>
              <ul className="nav m-auto mt-5">
                <li className="m-1">
                  <a
                    style={{ backgroundColor: "#4267B2" }}
                    target="_blank"
                    rel="noreferrer"
                    className="btn text-white"
                    href={"https://www.facebook.com/StudioAMKPteLtd/"}
                  >
                    <ImFacebook /> facebook
                  </a>
                </li>
                <li className="m-1">
                  <a
                    style={{ background: "#ff3333" }}
                    className="btn text-white"
                    target="_blank"
                    rel="noreferrer"
                    href={
                      "https://play.google.com/store/apps/developer?id=StudioAMK"
                    }
                  >
                    <SiGoogleplay /> Play Store
                  </a>
                </li>
                <li className="m-1">
                  <a
                    style={{ background: "#2072f3" }}
                    className="btn text-white"
                    target="_blank"
                    rel="noreferrer"
                    href={
                      "https://apps.apple.com/us/developer/studioamk/id517974772"
                    }
                  >
                    <FaAppStoreIos /> App Store
                  </a>
                </li>
                {/* <li className="m-1">
                  <a className="btn btn-secondary text-white" href={"#"}>
                    <ImTwitter /> twitter
                  </a>
                </li> */}
              </ul>
            </div>
          </div>
        </Container>
      </Container>
    </>
  );
};

export default Contact;

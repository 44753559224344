import "./Product.css";
import Slider from "react-slick";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Card from "react-bootstrap/Card";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { ImAndroid, ImChrome, ImAppleinc } from "react-icons/im";
import { useState } from "react";
import { useEffect } from "react";

const Product = () => {


  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide:0,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          autoplay: true,
        },
      },
      {
        breakpoint: 820,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <Container
        fluid
        id="product"
        className="product-bg-color align-items-center"
        style={{ paddingTop: "50px", paddingBottom: "50px" }}
      >
        <Container>
          <div className="row mb-3">
            <div className="our-product-title">
              <h1 className="h1 mb-4">
                Our <span className="header-style">Products</span>
                <br />
                {/* <div className="amk-title">
                  <mark>
                    Studio <span style={{ color: "red" }}>AMK</span>
                  </mark>{" "}
                </div> */}
              </h1>
            </div>
          </div>
          <div className="row">
            <Slider
              {...settings}
              // direction="horizontal"
              // style={{ overflow: "hidden" }}
            >
              <Card className="col">
                <Card.Body className="d-block">
                  <Image
                    src={process.env.PUBLIC_URL + "/images/shan.jpg"}
                    alt="logo"
                    style={{
                      borderRadius: "10px",
                    }}
                    className="m-auto w-80 logo mb-4"
                  />

                  <Card.Title>Shan Koe Mee</Card.Title>
                  <Card.Subtitle className="mb-2">Game</Card.Subtitle>
                  <Card.Text>
                    Shan Koe Mee is a traditional chess and card game which is
                    popular with the Burmese
                    <br />
                  </Card.Text>
                  <div className="mb-3">
                    <a
                      href="https://shankoemee.com/play/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {/* <Button className="game-button">Play</Button> */}
                      <button className="button-24">Play</button>
                    </a>
                  </div>
                  <ButtonGroup aria-label="Basic">
                    <button
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                        outline: "none",
                        color: "#fff",
                      }}
                    >
                      <a
                        href="https://play.google.com/store/apps/details?id=com.myanmargame.shan"
                        target={"_blank"}
                        rel="noreferrer"
                        style={{ textDecoration: "none", color: "#fff" }}
                      >
                        <ImAndroid /> Android
                      </a>
                    </button>
                    <button
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                        outline: "none",
                        color: "#fff",
                      }}
                    >
                      <a
                        href="https://shankoemee.com/play/"
                        target={"_blank"}
                        rel="noreferrer"
                        style={{ textDecoration: "none", color: "#fff" }}
                      >
                        <ImChrome /> Web
                      </a>
                    </button>
                  </ButtonGroup>
                </Card.Body>
              </Card>

              <Card className="col">
                <Card.Body className="d-block">
                  <Image
                    src={process.env.PUBLIC_URL + "/images/ywar.jpg"}
                    alt="logo"
                    style={{
                      borderRadius: "10px",
                    }}
                    className="m-auto w-80 logo mb-4"
                  />
                  <Card.Title>Ywar</Card.Title>
                  <Card.Subtitle className="mb-2">
                    Payment Platform
                  </Card.Subtitle>
                  <Card.Text>
                    YWAR Platform' is a digital content platform that can solve
                    payment problems for your app/game.
                    <br />
                  </Card.Text>
                  <div className="mb-3">
                    <a
                      href="https://www.ywar.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <button className="button-24">Visit</button>
                    </a>
                  </div>
                  <ButtonGroup aria-label="Basic">
                    <button
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                        outline: "none",
                        color: "#fff",
                      }}
                    >
                      <a
                        href="https://www.ywar.com"
                        target={"_blank"}
                        rel="noreferrer"
                        style={{ textDecoration: "none", color: "#fff" }}
                      >
                        <ImChrome /> Web
                      </a>
                    </button>
                  </ButtonGroup>
                </Card.Body>
              </Card>

              <Card className="col">
                <Card.Body className="d-block">
                  <Image
                    src={process.env.PUBLIC_URL + "/images/334.jpg"}
                    alt="logo"
                    style={{
                      borderRadius: "10px",
                    }}
                    className="m-auto w-80 logo mb-4"
                  />
                  <Card.Title>334's Adventures</Card.Title>
                  <Card.Subtitle className="mb-2">App</Card.Subtitle>
                  <Card.Text>
                    An exciting and educational app designed exclusively for curious minds aged 2 to 5 years old.
                  </Card.Text>
                  <div className="mb-3">
                    <a
                      href="https://play.google.com/store/apps/details?id=com.baby334.adventures"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <button className="button-24">Download</button>
                    </a>
                  </div>
                  <ButtonGroup aria-label="Basic">
                    <button
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                        outline: "none",
                        color: "#fff",
                      }}
                    >
                      <a
                        href="https://play.google.com/store/apps/details?id=com.baby334.adventures"
                        target={"_blank"}
                        rel="noreferrer"
                        style={{ textDecoration: "none", color: "#fff" }}
                      >
                        <ImAndroid /> Android
                      </a>
                    </button>
                    <button
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                        outline: "none",
                        color: "#fff",
                      }}
                    >
                      <a
                        href="https://apps.apple.com/us/app/334s-adventures/id6450992548"
                        target={"_blank"}
                        rel="noreferrer"
                        style={{ textDecoration: "none", color: "#fff" }}
                      >
                        <ImAppleinc /> ios
                      </a>
                    </button>
                  </ButtonGroup>
                </Card.Body>
              </Card>
              
              <Card className="col">
                <Card.Body className="d-block">
                  <Image
                    src={process.env.PUBLIC_URL + "/images/shwedagon.jpg"}
                    alt="logo"
                    style={{
                      borderRadius: "10px",
                    }}
                    className="m-auto w-80 logo mb-4"
                  />
                  <Card.Title>Shwedagon Pagoda</Card.Title>
                  <Card.Subtitle className="mb-2">App</Card.Subtitle>
                  <Card.Text>
                    We designed and donated the Shwedagon Pagoda Homage app to
                    make accessible to the public.
                  </Card.Text>
                  <div className="mb-3">
                    <a
                      href="https://play.google.com/store/apps/details?id=com.studioamk.shwedagonpagoda"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <button className="button-24">Download</button>
                    </a>
                  </div>
                  <ButtonGroup aria-label="Basic">
                    <button
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                        outline: "none",
                        color: "#fff",
                      }}
                    >
                      <a
                        href="https://play.google.com/store/apps/details?id=com.studioamk.shwedagonpagoda"
                        target={"_blank"}
                        rel="noreferrer"
                        style={{ textDecoration: "none", color: "#fff" }}
                      >
                        <ImAndroid /> Android
                      </a>
                    </button>
                    <button
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                        outline: "none",
                        color: "#fff",
                      }}
                    >
                      <a
                        href="https://apps.apple.com/us/app/shwedagon-pagoda/id1537827545"
                        target={"_blank"}
                        rel="noreferrer"
                        style={{ textDecoration: "none", color: "#fff" }}
                      >
                        <ImAppleinc /> ios
                      </a>
                    </button>
                  </ButtonGroup>
                </Card.Body>
              </Card>

              <Card className="col">
                <Card.Body className="d-block">
                  <Image
                    src={process.env.PUBLIC_URL + "/images/unicode.jpg"}
                    alt="logo"
                    style={{
                      borderRadius: "10px",
                    }}
                    className="m-auto w-80 logo mb-4"
                  />

                  <Card.Title>Unicode Hero</Card.Title>
                  <Card.Subtitle className="mb-2">Game</Card.Subtitle>
                  <Card.Text>
                    Unicode Hero is a typing game to improve your Unicode typing
                    skill.
                    <br></br>
                    <br></br>
                  </Card.Text>
                  <div className="mb-3">
                    <a
                      href="https://studio-amk.github.io/unicodehero/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <button className="button-24">Play</button>
                    </a>
                  </div>
                  <ButtonGroup aria-label="Basic">
                    <button
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                        outline: "none",
                        color: "#fff",
                      }}
                    >
                      <a
                        href="https://studio-amk.github.io/unicodehero/"
                        target={"_blank"}
                        rel="noreferrer"
                        style={{ textDecoration: "none", color: "#fff" }}
                      >
                        <ImChrome /> Web
                      </a>
                    </button>
                  </ButtonGroup>
                </Card.Body>
              </Card>
              
             
            </Slider>
          </div>
        </Container>
      </Container>
    </>
  );
};

export default Product;

import React from "react";
import {
  SidebarContainer,
  SidebarMenu,
  SidebarLink,
  SidebarItem,
} from "./SidebarElements";

const Sidebar = ({ toggle, isOpen }) => {
  return (
    <SidebarContainer isOpen={isOpen}>
      {/* <Icon onClick={toggle}>
        <CloseIcon />
      </Icon> */}
      <SidebarMenu>
        <SidebarItem>
          <SidebarLink to="product" onClick={toggle}>
            Products
          </SidebarLink>
          <SidebarLink to="service" onClick={toggle}>
            Services
          </SidebarLink>
          <SidebarLink to="activity" onClick={toggle}>
            Activities
          </SidebarLink>
          <SidebarLink to="contact" onClick={toggle}>
            Contact Us
          </SidebarLink>
        </SidebarItem>
      </SidebarMenu>
    </SidebarContainer>
  );
};

export default Sidebar;

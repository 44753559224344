import './Footer.css';
import Container from 'react-bootstrap/Container';

const Footer = () => {
  return (
    <>
      <Container fluid className="pb-3 pt-3 bg-dark">
        <div className="row">
          <div className="col text-center text-white">
            <center>Studio AMK © 2022 All rights reserved.</center>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Footer;
